.flex-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer_container {
  width: 100%;
  padding: 10px;
  flex-direction: column;
}

.top {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.aligned {
  width: 20%;
  height: 130px;
  flex-direction: column;
  justify-content: space-between;
}

#logo_footer {
  width: 70%;
}

#logo_footer > img {
  width: 40%;
}

.logodiv {
  width: 100%;
  justify-content: space-evenly;
  font-size: 2rem;
}

.logodiv > *:hover {
  cursor: pointer;
  color: var(--secondary-color);
}

.aligned > h2 {
  /* margin-bottom: 20px; */
}

@media screen and (max-width: 800px) {
  .top {
    flex-direction: column;
  }
  .aligned {
    width: 80%;
    margin: 20px 0;
    height: 130px;
  }
  .aligned > h2 {
    margin-bottom: 10px;
  }
}