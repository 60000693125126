.contactus__container {
    margin-top: 10vh;
    width: 100%;
    padding: 10px;
    min-height: 90vh;
    justify-content: flex-start;
    gap: 5rem;
}

.contactus__heading {
    font-size: 2.5rem;
    font-weight: 600;
    color: var(--secondary-color);
}

.contactus__form {
    width: 40%;
    justify-content: flex-start;
    gap: 1rem;
    font-size: 1.4rem;
}

.contactus__form > h2 {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    width: 100%;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    padding: 0.5rem;
}

.contactus__form__content {
    width: 100%;
}

.contactus__form__field {
    margin: 20px 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.contactus__form__input {
    width: 50%;
    border: none;
    box-shadow: none;
    padding: 0.7rem 0.5rem;
    border-radius: 5px;
}

.contactus__form__input:hover,
.contactus__form__input:focus,
.contactus__form__input:focus-within,
.contactus__form__input:enabled,
.contactus__form__input:disabled {
    border: 0;
    box-shadow: 0;
    outline: 0;
}

textarea {
    resize: none;
    height: 200px;
}

.contactus__form__button {
    margin-bottom: 40px;
}

.contactus__form__button > button {
    padding: 0.5rem 1rem;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    font-size: 1.2rem;
    border: 0;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    font-weight: 500;
    letter-spacing: 2px;
}

.contactus__form__button > button:hover {
    transform: scale(0.85);
    letter-spacing: 0px;
}

@media screen and (max-width: 900px) {
    .contactus__form {
        width: 90%;
    }
}