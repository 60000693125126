.chat-container {
    padding: 10px;
    gap: 2rem;
}

.chat-heading {
    font-size: 2.5rem;
    color: var(--secondary-color);
    font-weight: 600;
    font-style: italic;
}

.chat-question-container {
    width: 100%;
    padding: 20px;
    gap: 2rem;
}

.chat-card {
    padding: 20px;
    border-radius: 5px;
    font-size: 1.3rem;
    width: 80%;
    justify-content: space-evenly;
    gap: 2rem;
    background-color: white;
    transition: all 0.3s ease-in-out;
}

.chat-question {
    width: 100%;
    font-weight: 500;
    flex-direction: row;
    justify-content: space-between;
}

.chat-icons:hover {
    cursor: pointer;
}

.chat-answer {
    width: 100%;
    text-align: justify;
    transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 700px) {
    .chat-card {
        width: 90%;
    }
}