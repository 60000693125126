:root {
  /* --backg-blue: #012D70;
  --font-white: #ffffff;
  --primary-color: #14213d;
  --secondary-color: #fca311;
  --card-color: #f2f2f2;
  --supplementary-color: #ffffff;
  --supplementary-color: #eeeeee; */
  /* Latest Color Pallete */
  --primary-color: #eeeeee;
  --secondary-color: #e25011;
  --tertiary-color: #212121;
  --supplementary-color: #f69f21;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  z-index: 1;
  color: inherit;
  overflow-x: hidden;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--primary-color);
  color: var(--tertiary-color);
  overflow-x: hidden;
  width: 100vw;
}

html{
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fl-col-c-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.fl-row-c-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
