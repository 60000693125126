#gallery {
  color: var(--secondary-color);
  font-weight: 600;
  width: 100%;
  font-size: 2.5rem;
  font-style: italic;
  margin: 40px auto;
}

.carousel {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.slider img {
  aspect-ratio: 16/9;
  width: 80%;
  object-fit: cover;
}

.slider-icons {
  font-size: 2rem;
  color: var(--tertiary-color);
}

.slider-icons:hover {
  color: var(--secondary-color);
}

.slider-dot-container {
  display: flex;
  margin-top: 30px;
  align-items: center;
  gap: 5px;
}

.slider-dot {
  content: "";
  background-color: var(--tertiary-color);
  width: 1rem;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
}

#dot-active {
  background-color: var(--secondary-color);
}

@media screen and (min-width: 800px) {
  .carousel {
    width: 80%;
  }
}