.bot_list{
    background: #FFFFFF;
    box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    padding: 0.5vw;
    margin: auto;
    margin-top: 2vw;
    margin-bottom: 2vw;
    width: 70vw;
}
.bot_list .manage_btn{
    background: #012D70;
    color: white;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 25px;
    /* padding: 0.2vw 0.6vw; */
    font-weight: bold;
}

.manage_btn {
    padding: 5px 15px;
    border: none;
}

.bot_list td{
    padding: .3vw 0;
}

.bot_list th{
    padding: 0  0 .3vw 0;
    border-bottom: 2px solid #012D70;
}

#addBot {
    border: none;
    cursor: pointer;
    padding: 5px 16px;
    font-size: 1.05rem;
    background-color: green;
    color: white;
    border-radius: 16px;
}