.aboutus__container {
    margin-top: 13vh;
    padding: 10px;
    min-height: 100vh;
    justify-content: flex-start;
    gap: 3rem;
}

.aboutus__overall__heading {
    font-size: 1.2rem;
    color: var(--secondary-color);
}

.aboutus__inner__container {
    flex-direction: row;
    justify-content: space-evenly;
    min-height: 65vh;
}

.container__text {
    width: 45%;
    font-size: 1.25rem;
    line-height: 2;
    text-align: justify;
    text-align-last: center;
}

.container__text > div {
    width: 100%;
    font-size: 3rem;
    font-weight: 600;
}

#first-about-image {
    width: 40%;
    border-radius: 30px;
}

.aboutus__meetourteam__heading {
    color: var(--secondary-color);
    font-size: 1.5rem;
}

.aboutus__meetourteam__container {
    padding: 1rem;
    width: 100%;
    gap: 5rem;
}

.aboutus__meetourteam__card {
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    height: 300px;
}

.aboutus__meetourteam__image {
    height: 100%;
    border-radius: 50%;
    aspect-ratio: 1;
}

.aboutus__meetourteam__text {
    width: 45%;
    height: 65%;
    justify-content: space-evenly;
    font-size: 1.1rem;
}

.aboutus__meetourteam__text > span {
    color: var(--secondary-color);
    font-weight: 500;
}

.aboutus__meetourteam__text > p {
    text-align: justify;
    text-align-last: center;
}

.meetourteamcard__reverse {
    flex-direction: row-reverse;
}

@media screen and (max-width: 480px) {
    .aboutus__overall__heading {
        font-size: 1rem;
    }

    .aboutus__inner__container {
        flex-direction: column;
        gap: 3rem;
    }
    
    .aboutus__inner__container > * {
        width: 90%;
        font-size: 1.15rem;
    }

    #first-about-image {
        width: 90%;
    }

    .aboutus__meetourteam__heading {
        font-size: 1.5rem;
    }

    .aboutus__meetourteam__card {
        flex-direction: column;
        min-height: 600px;
        gap: 3rem;
    }

    .aboutus__meetourteam__image {
        height: 50%;
    }

    .aboutus__meetourteam__text {
        width: 95%;
        height: 45%;
        font-size: 1.15rem;
    }
}