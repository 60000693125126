#confirmation{
	width: 90%;
	padding: 3% 0;
	margin:  170px auto 0;
	text-align: center;
	background: white;
	color: black;
	box-shadow: 0px 0px 19px 8px rgba(0, 0, 0, 0.25);
	border-radius: 25px;
    margin-bottom: 8%;
}
#confirmation #login_btn{
	color: white;
	font-weight: bold;
	background: #012D70;
	padding: 2% 3%;
	margin-top: 5%;
	border-radius: 15px;
	box-shadow: 0px 0px 4px 4px rgba(0, 0, 0, 0.5);
}

#mail_icon{
	position: relative;
	background-color: rgba(1, 45, 112, 0.29);;
	width: fit-content;
	margin: auto;
	border-radius: 50%;
	padding: 1vw;
}
#sent_mail{
   width: 15vw;
   height: fit-content;
   /* background-color: blue; */
}
#resend{
	color: red;
	text-decoration: none;
	font-weight: bold;
}

html{
		font-size: smaller;
	}

@media screen and (min-width : 460px) {
	#sent_mail{
		width: 12vw;
	}
	html{
		font-size: medium;
	}
}
@media screen and (min-width : 768px) {
	#sent_mail{
		width: 10vw;
	}
	#confirmation{
		width: 50%;
	}
	html{
		font-size: medium;
	}
}
@media screen and (min-width : 1024px) {
	#sent_mail{
		width: 5vw;
	}
	#confirmation{
		width: 50%;
	}
	html{
		font-size: default;
	}
}
@media screen and (min-width : 1960px) {
	#sent_mail{
		width: 2vw;
	}
	#confirmation{
		width: 50%;
	}
}