#first100 {
    width: 100%;
    margin-top: 10vh;
    position: relative;
}

#first100 > img {
    width: 100%;
}

.captivating-text {
    position: absolute;
    left: 52vw;
    top: 36vh;
    font-weight: 600;
    color: var(--primary-color);
    width: 40vw;
    height: 50vh;
    font-size: 1.25rem;
}

/* .captivating-text > h1:nth-child(1) {
    text-align: justify;
    text-align-last: center;
} */

@media screen and (max-width : 600px) 
{
    #first100 {
        width: auto;
        height: 100vh;
    }
    #first100 > img {
        width: auto;
        height: 100%;
        object-position: center;
        transform: translateX(-25%);
    }
    .captivating-text {
        top: 30vh;
        left: 10vw;
        width: 80vw;
        font-size: 1.1rem;
        height: fit-content;
    }
}