.aboutus_container {
    width: 100%;
    padding: 1rem;
    margin: 50px auto;
}

.aboutus_heading {
    color: var(--secondary-color);
    text-align: center;
    font-weight: 600;
    font-size: 2.5rem;
    margin: auto;
    margin-bottom: 30px;
}

#contact {
    color: white;
    line-height: 1.5;
    width: 100%;
    margin: auto;
    text-align: center;
    padding: 2.5% 0;
}

#about_us {
    width: 100%;
    display: flex;
    scroll-margin-top: 160px;
    justify-items: center;
    margin: auto;
    text-align: center;
}

.aboutus_content {
    margin: auto;
    display: flex;
    justify-items: center;
}

#about_us div{
    width: 100%;
    box-sizing: border-box;
    /* text-align: center; */
    margin: auto;
    text-align: justify;
    text-align-last: center;
}

#about_us ol{
    display: inline-block;
    text-align: left;
}

.aboutus_paragraph {
    text-align: justify;
    line-height: 1.5;
    width: 60%;
}

@media screen and (min-width: 700px) {
    .aboutus_container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .aboutus_heading {
        width: 25%;
        text-align: right;
        font-size: 600;
        font-style: italic;
        margin: auto;
    }

    .aboutus_content {
        width: 70%;
        /* border: 1px solid red; */
    }

    .aboutus_paragraph {
        max-width: 90%;
    }

    .aboutus_paragraph strong {
        font-size: 1.2rem;
    }
}