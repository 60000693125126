.services_container {
    padding: 1vh 1vw;
}

.services_heading {
    color: var(--secondary-color);
    font-weight: 600;
    width : 100%;
    font-size: 2.5rem;
    margin: 30px auto;
}

.service-cards {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
}

.services {
    transition: 0.1s ease-out;
    border: 2px solid var(--secondary-color);
    border-radius: 20px;
    color: var(--tertiary-color);
    object-fit: contain;
    width: 18rem;
    height: 18rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-evenly;
    text-align: justify;
    text-align-last: center;
    /* padding: 30px 20px 30px 20px; */
    padding: 20px;
    margin: 20px 10px;
    vertical-align: top;
}

.services > h3 {
    text-align: center;
}

.services:hover{
    transform: scale(0.95);
    transition: 0.2s all ease-in;
}

.services div img{
    max-width: 100%;
    max-height: 100%;
}

.services div{
    background: rgba(236, 91, 126, 0.29);
    padding: 10px;
    border-radius: 9px;
    /* position: relative; */
    /* margin: auto; */
    height: 20%;
    aspect-ratio: 1;
    overflow: hidden;
    /* border: 1px solid red; */
}

.services span {
    width: 15em;
    height: 33%;
}

#services{
    text-align: center;
    margin: auto;
    /* box-sizing: border-box; */
    /* padding: 5%; */
    width: 90%;
    /* margin-top: 10%; */
    /* margin: 0 auto 5% ; */
    scroll-margin-top: 130px;
    text-align: center;
}

@media screen and (min-width: 900px) {
    .services_container {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
    }
    
    .services_heading {
        width: 20%;
        text-align: right;
        font-weight: 700;
        font-style: italic;
    }

    #services {
        width: 80%;
    }
}