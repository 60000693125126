.proper-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
  width: 100%;
  min-height: max-content;
  padding: 1vw;
}

.proper-div > * {
  font-family: 'Rubik', sans-serif;
}

.dash-default-container {
  min-height: 100vh;
}

.dash-default-heading {
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.dash-default-inner-container {
  padding: 15px;
  border-radius: 15px;
}

.dash-default-inner-container-heading {
  font-size: 1.6rem;
  font-weight: 500;
}

.dash-default-firstcontainer-card-container {
  flex-direction: row;
  width: 80%;
  align-items: center;
  justify-content: space-evenly;
}

.dash-default-firstcontainer-card-container > div {
  width: 20%;
}

.dash-default-firstcomp {
  flex-direction: row;
  justify-content: space-between;
  height: min-content;
  width: 80%;
}

.robocontrol-container {
  justify-content: center;
  gap: 30px;
}

.robocontrol-heading {
  color: var(--tertiary-color);
}

.robocontrol-dropdown {
  border: none;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  font-size: 1.75rem;
  padding: 0.5rem 1rem;
}

.Robo {
  height: 100%;
}

.robocontrol-outerbox {
  width: 100%;
}

#operate-img {
  width: 30%;
}

.robocontrol-list{
  flex-direction: column;
  gap: 30px;
  height: max-content;
}

/* DashProfile  */
.dashprof-wrapper{
  padding: 30px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 5vh;
}

.dashprof-heading {
  font-size: 2.8rem;
  font-weight: 600;
  letter-spacing: 2px;
}

.dashprof-form {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  gap: 50px;
  padding: 20px;
}

.dashprof-fields {
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.dashprof-fields input {
  border: none;
  color: inherit;
  background-color: inherit;
  border-radius: 10px;
  padding: 8px;
  width: 40%;
  height: 36px;
  border: 1px solid var(--tertiary-color);
  font-size: 1rem;
}

.dashprof-fields input:focus {
  border: 2px solid var(--tertiary-color);
  box-shadow: 0;
}

.dashprof-indi {
  width: 40%;
  text-align: end;
}

.profile-submit {
  padding: 0.5rem 2rem;
  border: 0;
  border-radius: 1rem;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Rubik', sans-serif;
  transition: all 0.5s ease-in-out;
}

.profile-submit:hover {
  color: var(--supplementary-color);
  transform: scale(0.9);
}

/* Dash Operate */
.dash-operate-container {
  padding: 20px;
  justify-content: flex-start;
  height: 100%;
}

.dash-operate-operation {
  height: 80%;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
}

.dash-operate-list-container {
  height: 50%;
  justify-content: space-evenly;
}

.dash-operate-list-dropdown {
  border: 0;
  padding: 0.3rem 0.8rem;
  font-family: 'Rubik', sans-serif;
  font-size: 1.5rem;
  background-color: var(--tertiary-color);
  color: var(--primary-color);
  border-radius: 5px;
}

#operate-img {
  width: 33%;
}

/* Video Control  */
.video-control-meet,
.dash-operate-video-container {
  width: 100%;
  height: 100%;
  justify-content: center;
}

.video-control-meet > iframe {
  padding: 10px;
  width: 100%;
  border-radius: 30px;
  /* height: 100%; */
}