.signin_container {
  display: flex !important;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 60vh;
  background-color: var(--primary-color);
  color: var(--primary-color);
  border-radius: 10px;
  max-width: 60vw;
}

.signin_container form {
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 15px;
  background: #fff;
  text-align: center;
}

.signin_container p {
  color: red;
}

.signin_container button {
  border-radius: 12px;
  padding: 0.4em 2em;
  background: var(--secondary-color);
  font-weight: bold;
  color: var(--tertiary-color);
  cursor: pointer;
  margin: 10px 0;
}

#loginpage-img {
  width: 50%;
}

#signin-button:hover {
  cursor: pointer;
  background-color: var(--tertiary-color);
  color: var(--secondary-color);
}

/* New design of signin page  */
.signin_container {
  width: 75vw;
  min-height: 73vh;
  background-color: var(--primary-color);
  border: none;
  border-radius: 25px;
  margin: 10vh auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  color: var(--tertiary-color);
}

.signin_container * {
  overflow-x: visible;
}

.signin_container input,
.signin_container label {
  color: var(--tertiary-color);
}

.form-wrapper {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 40%;
  gap: 1rem;
}

#eye-button {
  background-color: inherit;
  color: inherit;
  padding: 0 10px;
  margin-left: 10px;
}

.log-sign-heading {
  font-size: 2rem;
  font-weight: 700;
  font-family: 'Rubik', sans-serif;
  margin-bottom: 10px;
}

#error_display {
  color: red;
  width: 90%;
  padding: 5px 10px;
  z-index: 100;
  font-family: 'Rubik', sans-serif;
}

@media screen and (max-width: 900px) {
  .signin_container {
    flex-direction: column;
  }
  #loginpage-img {
    width: 100%;
  }
  .form-wrapper {
    width: 100%;
  }
  .signin_container form {
    width: 60%;
  }
  .signin_container input[type="text"],
  .signin_container input[type="password"] {
    font-size: medium;
  }
}
