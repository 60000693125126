.product__container {
    margin-top: 10vh;
    padding: 10px;
    width: 100%;
    min-height: 100vh;
    gap: 3rem;
}

.product__cover {
    flex-direction: row; 
    width: 100%;
    justify-content: space-evenly;
}

.column__align__left {
    flex-direction: row;
}

.column__align__right {
    flex-direction: row-reverse;
}

.product__cover > img {
    width: 35%;
}

.product__text {
    min-height: 400px;
    justify-content: space-evenly;
    width: 45%;
    gap: 1.5rem;
}

.product__title {
    font-weight: 600;
    /* font-style: italic; */
    color: var(--secondary-color);
    font-size: 3rem;
}

.product__description {
    font-size: 1.3rem;
    line-height: 1.5;
    text-align: justify;
    text-align-last: center;
}

.button__buyrobot {
    font-size: 1.5rem;
    color: var(--primary-color);
    background-color: var(--tertiary-color);
    padding: 0.8rem 2rem;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out;
}

.button__buyrobot:hover {
    transform: scale(0.85);
    color: var(--secondary-color);
}

@media screen and (max-width: 900px) {
    .product__cover {
        flex-direction: column;
        gap: 2rem;
    }
    .product__cover > img {
        width: 60%;
    }
    .product__text {
        width: 95%;
    }
}